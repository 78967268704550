<template>
  <ul class="menu-nav" v-if="$store.state.auth.normal">
    <button ref="hiddenButton" @click="enableAudio" style="display: none;">Enable Audio</button>

    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text ">Dashboard</span>
        </a>
      </li>
    </router-link>
    <li class="menu-section">
      
      
      <h4 class="menu-text">Orders</h4><i class="menu-icon flaticon-more-v2"></i></li>
    <router-link
      to="/orders/today"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-bullet ">
            <span></span>
          </i>
          <span class="menu-text">Today</span>
        </a>
      </li>
    </router-link>


    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu "
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-bullet "></i>
        <span class="menu-text">History</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">History</span>
            </span>
          </li>

          <router-link
              to="/orders/combined"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Combined</span>
                </a>
              </li>
            </router-link>

            <router-link
              to="/orders/cancelled"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Cancelled</span>
                </a>
              </li>
            </router-link>

            <router-link
              to="/orders/history"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">History</span>
                </a>
              </li>
            </router-link>
        </ul>
      </div>
    </li>

    <router-link
        to="/business/reservations"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet">
              <span></span>
            </i>
            <span class="menu-text">Table Booking</span>
          </a>
        </li>
      </router-link>

    <li class="menu-section"><h4 class="menu-text">Menu</h4><i class="menu-icon flaticon-more-v2"></i></li>
    <router-link
      to="/menu-manager/categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-bullet">
            <span></span>
          </i>
          <span class="menu-text">Categories</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/menu-manager/products"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-bullet">
            <span></span>
          </i>
          <span class="menu-text">Products</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/menu-manager/toppings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-bullet">
            <span></span>
          </i>
          <span class="menu-text">Toppings</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/menu-manager/properties"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-bullet">
            <span></span>
          </i>
          <span class="menu-text">Dish Options/Attributes</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section"><h4 class="menu-text">Settings</h4><i class="menu-icon flaticon-more-v2"></i></li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-error/error'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-bullet"></i>
        <span class="menu-text">Business Setting</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Business Setting</span>
            </span>
          </li>

          <!-- <router-link
            to="/business/info"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Business Info</span>
              </a>
            </li>
          </router-link> -->

          <!-- <router-link
            to="/business/templateinfo"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Edit Template</span>
              </a>
            </li>
          </router-link> -->

          <router-link
            to="/business/templateinfo"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Theme</span>
              </a>
            </li>
          </router-link>

           <router-link
            to="/business/customers"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Customers</span>
              </a>
            </li>
          </router-link>
           <router-link v-if="set_branch==0"
            to="/business/branches"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Branches</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/business/contact-us"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Messages</span>
              </a>
            </li>
          </router-link>


          <router-link
            to="/business/announcements"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Discounts / Vouchers</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/business/announcement"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Announcements</span>
              </a>
            </li>
          </router-link>

          <!-- <router-link
            to="/business/delivery-collection"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Delivery/Collection Info</span>
              </a>
            </li>
          </router-link> -->

          <!-- <router-link
            to="/business/opening-times"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Opening Times</span>
              </a>
            </li>
          </router-link> -->

          <router-link
            to="/business/wizard"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Wizard</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/business/reservation-settings"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reservation Settings</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/business/settings"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Settings</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/business/pos-import"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">POS Product Import</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-error/error'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-bullet"></i>
        <span class="menu-text">System Settings</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">System Settings</span>
            </span>
          </li>
           <router-link
            to="/system-setting/contact-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Contact Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/restaurant-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Restaurant Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/menu-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Menu Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/social-link-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Social Link Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/branding-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Branding Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/opening-timings-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Opening Timings Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/delivery-charges-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Delivery Charges Details</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/system-setting/delivery-collection-details"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Delivery Collection Details</span>
              </a>
            </li>
          </router-link>
          <router-link
              to="/system-setting/domain"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Domain</span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/system-setting/qrcode"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">QR Code</span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/system-setting/account-delete"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Delete your Account</span>
                </a>
              </li>
            </router-link>
        </ul>
      </div>
    </li>
  </ul>

  <ul class="menu-nav" v-else-if="!$store.state.auth.normal">
    <!-- <router-link to="/admin/" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
        :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
        <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi-view-dashboard-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                    aria-hidden="true" density="comfortable"></i>
                <span class="menu-text ">Dashboard</span>
            </a>
        </li>
    </router-link> -->

    
    <router-link to="/admin" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="mdi mdi-home menu-icon"></i>
              <span class="menu-text ">Dashboard</span>
            </a>
        </li>
    </router-link>





    <router-link to="/admin/businesses" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-briefcase-outline  menu-icon"></i>
                <span class="menu-text ">Businesses</span>
            </a>
        </li>
    </router-link>



    <router-link to="/admin/reservation" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-calendar-outline  menu-icon"></i>
                <span class="menu-text ">Table Reservations</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/categories" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-tag-outline  menu-icon"></i>
                <span class="menu-text ">Categories</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/dish-properties" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-palette-outline  menu-icon"></i>
                <span class="menu-text ">Dish Properties</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/dish-properties-group" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="mdi mdi-palette-outline  menu-icon"></i>
              <span class="menu-text ">Dish Properties Group</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/toppings" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-pizza  menu-icon" ></i>
                <span class="menu-text ">Toppings</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/topping-groups" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-pizza menu-icon" ></i>
                <span class="menu-text ">Topping Groups</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/items" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-basket  menu-icon"></i>
                <span class="menu-text ">Items</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/opening-times" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-clock-outline  menu-icon"></i>
                <span class="menu-text ">Opening Times</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/delivery-collection-timings" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-truck-delivery  menu-icon"></i>
                <span class="menu-text ">Delivery & Collection Timings</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/orders" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-cart-minus  menu-icon"></i>
                <span class="menu-text ">Orders</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/vouchers" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-gift-outline  menu-icon"></i>
                <span class="menu-text ">Voucher Codes</span>
            </a>
        </li>
    </router-link>

    <router-link to="/admin/user-vouchers" v-slot="{ href, navigate, isActive, isExactActive }">
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item"
            :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
            <a :href="href" class="menu-link" @click="navigate">
                <i class="mdi mdi-gift-outline  menu-icon"></i>
                <span class="menu-text ">Used Vouchers</span>
            </a>
        </li>
    </router-link>
</ul>

</template>

<script>
// import Pusher from 'pusher-js';
import { messaging, onMessage, } from "../../../firebase";

export default {
  data() {
    return {
      isAudioEnabled: false,
      set_branch: sessionStorage.getItem("SELECTED_BRANCH") || "0",
    };
  },
  name: "KTMenu",
  mounted() {
    this.listenForNotifications();

        // Initialize Pusher
      //  Pusher.logToConsole = true;

        // const pusher = new Pusher('8f759dce5537c6587c47', {
        //     cluster: 'eu',
        //     //encrypted: true
        // });

        // // Subscribe to the 'orders' channel
        // const channel = pusher.subscribe('my-channel');
  
        // // Listen for the OrderShipped event
        // console.log('$store.state.auth',this.$store.state.auth.user.id)
        // channel.bind('my-event', (data) => {
        //     console.log('Order shipped:', data.order);
        //     if(this.$store.state.auth.user.id==data.message){
        //       this.$refs.hiddenButton.click();
        //     }

        // });



               console.log('fcm_token',this.$store.state.auth)

    },
  methods: {
    makeToast(variant, message) {
      this.$bvToast.toast(message, {
        title: "Message",
        variant: variant,
        solid: true
      });
    },
    listenForNotifications() {
  // Listen for notifications
  onMessage(messaging, (payload) => {
    console.log("Message received:", payload);

    // Access the custom `notification_for` key from the payload data
    const notificationFor = payload?.data?.notification_for;

    // Handle different notification types
    if (notificationFor === "food_order") {
      // Navigate to today's orders and show a toast for a new order
      this.$router.push('/orders/today');
      this.makeToast("success", "New Food Order Received");
    } else if (notificationFor === "tablebooking") {
      // Navigate to table bookings and show a toast for a new table booking
      this.$router.push('/business/reservations');
      this.makeToast("success", "New Table Booking Received");
    } else {
      // Default action for unknown notification types
      console.warn("Unknown notification type:", notificationFor);
      this.makeToast("warning", "Notification received");
    }
  });
},

    enableAudio() {
      this.isAudioEnabled = true;
      for (let i = 0; i < 3; i++) {
          setTimeout(() => {
            this.playAudio();
          }, i * 2000);
        }
    },
    playAudio() {
        const audio = new Audio(process.env.VUE_APP_API_BASE_URL + "beep.mp3");
        audio.play().catch((error) => {
            console.error('Error playing audio:', error);
        });
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
<style >
.text-primary{
  color: #5D5BA0 !important;
}
.bg-primary{
  background-color: #4d4a9e !important;
}
.text-indego{
  color: #2DC7C0 !important;
}
ss
.bg-primary text-light{
  background-color: #2DC7C0 !important;
  color: white !important;
}
.flaticon2-list-2{
  color: #9B92DD !important;

}

.clear-filters{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.theme--light.v-pagination .v-pagination__item--active{
  color:#8D8C96 !important;
  font-size: large;
}

.aside-menu .menu-nav :hover{
  background-color: #4d4a9e !important;

}
.aside-menu .menu-nav > .menu-item{
  border-top: 0.1px solid gray;
  border-bottom: 0.1px solid gray;

}
 .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color:#F6FBFF !important;
  font-family:Poppins,sans-serif  !important;
  font-weight: bold!important;
  font-size: 14px !important;
  letter-spacing: normal!important;
  line-height: 1rem!important;  

}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #4d4a9e !important;
  color:#F6FBFF !important;
  font-family:Poppins,sans-serif  !important;
  font-weight: bold!important;
  font-size: 1rem !important;
  letter-spacing: normal!important;
  line-height: 1rem!important;  


}
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow{
  color:gray !important;

}
.aside-menu .menu-nav > .menu-item .menu-link:hover {
  /* background-color: #696161 !important; */
  color: #304FFD !important;
  
  
}
.aside-menu .menu-nav > .menu-section .menu-text{
  color:#A0A1CA !important;
  font-family:Poppins,sans-serif  !important;
  font-weight: bold!important;
  font-size: 1rem !important;
  letter-spacing: normal!important;
}

.aside-menu .menu-nav > .menu-item .menu-link.menu-text :hover{

  color: #304FFD !important;

} 
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link{
  background-color: #4d4a9e !important;

}
.aside-menu .menu-nav > .menu-item > .menu-link:hover{
  background-color: transparent !important;
}
.menu-item .menu-submenu .menu-item.menu-item-active > .menu-link{
  background-color: #ecebf9 !important;

}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text{

  color:#F6FBFF !important;
  font-family:'Poppins',sans-serif  !important;
  font-weight: bold!important;
  font-size: 1rem !important;
  letter-spacing: normal!important;
  line-height: 1rem!important;  
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link{
  background-color: #4d4a9e  !important;
  color:#A0A1CA !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-text{
  color:#F6FBFF !important;

} 
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text{
  color:#F6FBFF !important;
  font-weight: bold !important;
  font-family:'Poppins',sans-serif  !important;

}
.menu-icon{
  color:#F6FBFF !important;
}

/* Global styles */
tbody tr {
  height: 60px !important /* Adjust the height as needed */
}

tbody tr  td {
  vertical-align: middle !important; /* Adjust the height as needed */
  padding: 16px !important; /* Adjust the height as needed */
  font-family:'Poppins',sans-serif  !important;
  font-size: 1rem!important;


}
.v-data-table-header tr th {
  color:#2E304E !important;
  font-weight:600 !important;
  font-size:13px!important;
  font-family:'Poppins',sans-serif  !important

}
.card-custom{
  margin-top: 20px;
  border-radius: 10px !important;
}
/* #kt_content{
  background-color: #F8F7FA !important;
} */
.order-status{
  font-family:'Poppins',sans-serif  !important;
  font-weight: 700!important;

}
.btn-success{
  background-color: #5D5BA0 !important;
}
.btn-primary{
  background-color: #5D5BA0 !important;
}
.menu-arrow{
  color: red !important;
  font-size: 15px !important;
}
.font-poppins{
  font-family: 'Poppins', sans-serif!important;
}
.badge{
  border-radius: 1rem !important;
}
.v-data-table__mobile-row__header{
  margin-bottom: 23px !important;
    font-family: 'Poppins', sans-serif!important;

}
.v-data-table__wrapper{
  margin-bottom: 20px;
}
.v-data-table__mobile-table-row{
  margin-bottom: 20px;

}

  

</style>