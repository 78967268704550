<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--
    <div
      @click="takeMeToWeb"
      class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
    >
    <i class="bi bi-droplet-half fs-5x"></i>
      <span class="svg-icon svg-icon-xl svg-icon-primary">
        <inline-svg src="/media/svg/icons/Home/Earth.svg" />
      </span>
      <span class="pulse-ring"></span>
    </div>
  -->
    <div class="d-flex align-items-center mx-1">
      <!-- Button -->
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 ">
        <slot></slot>
      </div>

      <!-- Branch Selector (Shown Only if BRANCHES_COUNT > 0) -->
      <div v-if="showBranchSelect" class="ml-2 d-flex align-items-center">
        <label class="mr-2 my-1">Branch:</label>
        <select v-model="selectedBranch" @change="onBranchChange" class="form-control">
          <option value="0">{{ main_branch.name }} - {{ main_branch.address }}</option>
          <option v-for="branch in branches" :key="branch.id" :value="branch.id">
            {{ branch.name }} - {{ branch.address }}
          </option>
        </select>
      </div>
    </div>

    <!-- <label @click="takeMeToWeb" class="mr-3 mt-4">Go to Website</label> -->
    <i class="fa fa-globe mt-3 mr-5 text-primary" v-if="$store.state.auth.normal" @click="takeMeToWeb"
      title="View Website" aria-hidden="true" style="font-size:25px;cursor:pointer">
    </i>

    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
//import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
//import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
//import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
//import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
//import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      showBranchSelect: false,
      selectedBranch: null,
      main_branch: null,
      branches: [],
      languages: i18nService.languages
    };
  },
  components: {
    //KTSearchDefault,
    KTDropdownNotification,
    //KTDropdownQuickAction,
    //KTDropdownMyCart,
    //KTDropdownLanguage,
    KTQuickUser,
    //KTQuickPanel
  },
  mounted() {
    this.checkBranchCount();
    this.checkSelectedBranch();
    this.main_branch = JSON.parse(sessionStorage.getItem("MAIN_BRANCH"));
      // console.log(JSON.parse(this.main_branch).name);
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    checkBranchCount() {
      const branchCount = sessionStorage.getItem("BRANCHES_COUNT");
      
      console.log('BRANCHES_COUNT', branchCount,12);
      if (branchCount && parseInt(branchCount) > 0) {
        this.showBranchSelect = true;
        this.loadBranches(); // Call API to populate branches
      }
    },
    loadBranches() {
      ApiService.get("business/branches").then(({ data }) => {
        this.branches = data.data; // Assuming API returns { data: [{id: 1, name: 'Branch 1'}, ...] }
        this.selectedBranch = sessionStorage.getItem("SELECTED_BRANCH") || "0";
      });
    },
    onBranchChange() {
      sessionStorage.setItem("SELECTED_BRANCH", this.selectedBranch);
      location.reload(); // Reload the page
    },
    checkSelectedBranch() {
      const storedBranch = sessionStorage.getItem("SELECTED_BRANCH");
      this.selectedBranch = storedBranch !== null ? storedBranch : "0";
    },
    takeMeToWeb() {
      console.log(this.currentUserPersonalInfo.store_url);
      window.open(this.currentUserPersonalInfo.store_url, '_blank');
    }
  },
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
    ]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
